import { policyList } from "@/enums/permissions";
import { InventorySettings } from "@/interfaces/inventorySettings";
import { Location } from "@/interfaces/location";
import { i18n } from "@/plugins/i18n";
import { inventorySettings } from "@/services/inventorySettings.service";
import { BooleanCheck, Callback, PageNavAction } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import Template from "./InventorySettings.template.vue";

@Component({
  mixins: [Template]
})
export default class InventorySettingsComponent extends Vue {
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Mutation("setCurrentInventorySettings", { namespace: "AuthModule" })
  public setInventorySettings!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public policyList = policyList;

  public items = [
    {
      id: "SECONDARY_ID",
      name: i18n.t("inventory_settings_view.secondary_id")
    },
    { id: "NDC_NUMBER", name: i18n.t("inventory_settings_view.ndc_number") },
    { id: "NONE", name: i18n.t("inventory_settings_view.none") }
  ];

  public dropdownType: string | null = null;

  public back() {
    this.$router.back();
  }

  public async save() {
    if (!this.dropdownType) {
      return this.back();
    }
    if (this.hasPermission(this.policyList.modifyInventorySettings)) {
      this.setInventorySettings(
        await inventorySettings.updateSettings(this.dropdownType)
      );
    }
    // If you run the push without the timeout the request above will run so fast that you cannot be able to show the success notification
    setTimeout(() => {
      this.$router.push("/inventory/products-view");
    }, 1000);
  }

  protected async mounted() {
    this.setPageNav({
      title: "inventory_settings",
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.save
          },
          {
            icon: "fal fa-times",
            action: this.back
          }
        ]
      }
    });
    const res = await inventorySettings.getSettings();
    this.dropdownType = res
      ? res.data.stock_identification_type
      : this.currentLocation.state!.code === "CT"
      ? "NDC_NUMBER"
      : "SECONDARY_ID";
    if (this.currentLocation.state!.code !== "CT") {
      this.items = this.items.filter(item => item.id !== "NDC_NUMBER");
    }
  }
}
